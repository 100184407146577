// extracted by mini-css-extract-plugin
export var blob = "header-module--blob--68a0a";
export var header = "header-module--header--48f8f";
export var headerItems = "header-module--headerItems--228c8";
export var icon = "header-module--icon--b4533";
export var image = "header-module--image--23113";
export var onion = "header-module--onion--7c28c";
export var onionImage = "header-module--onionImage--37ade";
export var shadow = "header-module--shadow--cb5ee";
export var sliderHandle = "header-module--sliderHandle--8329f";
export var welcomeTitle = "header-module--welcomeTitle--190e5";