// extracted by mini-css-extract-plugin
export var contactList = "contact-module--contactList--4ac50";
export var container = "contact-module--container--7fe7c";
export var content = "contact-module--content--c7ef3";
export var contentChild = "contact-module--contentChild--799eb";
export var divider = "contact-module--divider--7ff6e";
export var heading = "contact-module--heading--390da";
export var link = "contact-module--link--466ba";
export var listIcon = "contact-module--listIcon--eb060";
export var listItem = "contact-module--listItem--f5378";
export var listText = "contact-module--listText--eaf88";